import React                from 'react'
import cx                   from 'utils/classnames'
import styles               from './styles.module.scss'

const FormControlInputArea = ({ className, children }) => (
    <div className={cx(styles.root, className)}>
        {children}
    </div>
)

export default FormControlInputArea
