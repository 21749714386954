import React                from 'react'
import InputLabel           from 'entrada-ui/InputLabel'
import cx                   from 'utils/classnames'
import styles               from './styles.module.scss'

const FormControlLabel = ({
    className,
    label,
    StartIcon,
    id,
    'aria-describedby': ariaDescribedby,
    ...LabelProps
}) => {
    const iconSize = StartIcon && StartIcon.props.fontSize

    return (
        <div className={cx(styles.root, className)}>
            {StartIcon &&
            <div className={cx(styles.icon, styles.mainSearchIcon)}>
            {StartIcon}
            </div>
            }

            {label &&
            <InputLabel
                id={id}
                classes={{
                    filled: StartIcon && styles[`label-icon-${iconSize}-filled`]
                }}
                aria-describedby={ariaDescribedby}
                {...LabelProps}
            >
                {label}
            </InputLabel>
            }
        </div>
    )
    }
export default FormControlLabel
