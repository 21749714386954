import React                from 'react'
import PropTypes            from 'utils/prop-types'
import cx                   from 'utils/classnames'
import FormControl          from 'entrada-ui/FormControl'
import FormHelpText         from 'entrada-ui/FormHelpText'
import TextFieldInput       from './TextFieldInput'
import styles               from './styles.module.scss'

const TextField = React.memo(React.forwardRef(function TextField(props, ref) {
    const {
        classes = {},
        className: classNameRoot,
        id,
        label,
        fullWidth,
        StartIcon,
        touched,
        error,
        disabled,
        required,
        LabelProps,
        InputProps = {},
        formControlRef,
        inputRef,
        helperText,
        onMetaChange,
        ...restProps
    } = props

    const InputLabelId = id ? `${id}-input-label` : undefined
    const FormHelpTextId = id && (helperText || (touched && error)) ? `${id}-help-text` : undefined

    const iconSize = StartIcon && StartIcon.props.fontSize

    const InputClasses = InputProps.classes || {}

    return (
        <FormControl
            ref={formControlRef}
            className={cx(
                styles.root,
                classes.root,
                classNameRoot
            )}
            onMetaChange={onMetaChange}
            touched={touched}
            error={error}
            disabled={disabled}
            required={required}
            fullWidth={fullWidth}
        >
            <FormControl.InputArea>
                <FormControl.Label
                    label={label}
                    StartIcon={StartIcon}
                    id={InputLabelId}
                    aria-describedby={FormHelpTextId}
                    {...LabelProps}
                />

                <TextFieldInput
                    {...restProps}
                    {...InputProps}
                    ref={inputRef}
                    classes={{
                        ...InputClasses,
                        root: cx(
                            StartIcon && styles[`input-with-icon-${iconSize}`],
                            InputClasses.root
                        ),
                        filled: cx(
                            label && styles['input-with-label-filled'],
                            InputClasses.filled
                        ),
                    }}
                    aria-labelledby={InputLabelId}
                    aria-describedby={FormHelpTextId}
                    type="text"
                />
            </FormControl.InputArea>

            <FormHelpText id={FormHelpTextId}>
                {helperText}
            </FormHelpText>
        </FormControl>
    )
}))

TextField.displayName = 'TextField'

TextField.defaultProps = {
    fullWidth: false,
}


TextField.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,

    id: PropTypes.string,

    name: PropTypes.string,
    value: PropTypes.oneOfType([
        () => null,
        PropTypes.string
    ]),
    onChange: PropTypes.func,

    required: PropTypes.bool,
    disabled: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([
        () => undefined,
        PropTypes.bool,
        PropTypes.string,
    ]),

    rtl: PropTypes.bool,

    label: PropTypes.string,
    fullWidth: PropTypes.bool,

    LabelProps: PropTypes.object,
    InputProps: PropTypes.object,
    // FormHelpTextProps: PropTypes.object,

    helperText: PropTypes.node,

    /**
     * This is useful if we want to render the field error outside of the component.
     */
    onMetaChange: PropTypes.func,

    children: PropTypes.node,
}

export default TextField
