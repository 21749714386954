import React                  from 'react'
import PropTypes              from 'utils/prop-types'
import cx                     from 'utils/classnames'
import { FormControlContext } from 'entrada-ui/FormControl'
import {
    useContext,
    getContextState
}                             from 'entrada-ui/utils'
import styles                 from './styles.module.scss'

const FormHelpText = (props) => {
    const {
        id,
        classes = {},
        className: classNameRoot,
        component: Component = 'p',
        children,
    } = props

    const context = useContext(FormControlContext)
    const { error, touched } = getContextState({ props, states: ['error', 'touched'], context })

    const showError = touched && (typeof error !== 'undefined')
    const hasErrorText = typeof error === 'string'

    return (
        children || (showError && hasErrorText) ?
        <Component
            id={id}
            className={cx(
                styles.root,
                classes.root,
                classNameRoot,
                {
                    [styles.error]: showError,
                    [classes.error]: showError
                }
            )}
            // Required by ARIA for errors
            role={showError ? 'alert' : undefined}
        >
            {touched && typeof error === 'string' ?
                error :
                children
            }
        </Component>
        : null
    )
}


FormHelpText.propTypes = {
    id: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    component: PropTypes.string,
    children: PropTypes.node,
}

export default FormHelpText
