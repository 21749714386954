import React                  from 'react'
import PropTypes              from 'utils/prop-types'
import cx                     from 'utils/classnames'
import { FormControlContext } from 'entrada-ui/FormControl'
import {
    useContext,
    getContextState
}                             from 'entrada-ui/utils'
import styles                 from './styles.module.scss'

const InputLabel = React.memo((props) => {
    const {
        id,
        className,
        classes = {},
        children,
        component: Component = 'label',
        disableAnimation = false
    } = props

    const context = useContext(FormControlContext)
    const { filled, error, touched } = getContextState({
        props,
        states: ['filled', 'error', 'touched'],
        context
    })

    return (
        <Component
            id={id}
            className={cx(
                styles.root,
                {
                    [styles['form-control']]: !!context,
                    [styles.animated]: !disableAnimation,
                    [styles.filled]: filled,
                    [styles.error]: touched && error,
                },
                className,
                classes && {
                    [classes['form-control']]: !!context,
                    [classes.animated]: !disableAnimation,
                    [classes.filled]: filled,
                    [classes.error]: touched && error,
                },
                classes.root
            )}
        >{children}</Component>
    )
})

InputLabel.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object,
    children: PropTypes.node.isRequired,
    component: PropTypes.string,
    disableAnimation: PropTypes.bool,
}

export default InputLabel
