import React                from 'react'
import PropTypes            from 'utils/prop-types'
import cx                   from 'utils/classnames'
import {
    isEtgElement,
    isFilled
}                           from 'entrada-ui/utils'
import FormControlInputArea from './FormControlInputArea'
import FormControlLabel     from './FormControlLabel'
import styles               from './styles.module.scss'

const FormControl = React.forwardRef(function FormControl(props, ref) {
    const {
        classes = {},
        className: classNameRoot,
        children,
        fullWidth,
        touched,
        error,
        disabled,
        required,
        component: Component = 'div',
        variant = 'standard',
        onMetaChange,
        ...wrapperProps
    } = props

    const [focused, setFocused] = React.useState(false)

    React.useEffect(() => {
        if(onMetaChange) {
            onMetaChange({ error, touched })
        }
    }, [error, touched, onMetaChange])

    const [filled, setFilled] = React.useState(() => {
        let initialFilled = false;

        if (children) {
          React.Children.forEach(children, child => {
            if (!isEtgElement(child, ['Input', 'Select'])) {
                return;
            }

            if (isFilled(child.props, true)) {
              initialFilled = true;
            }
          });
        }

        return initialFilled;
    });

    const onFilled = React.useCallback(() => setFilled(true), [])

    const onEmpty = React.useCallback(() => setFilled(false), [])

    const childContext = React.useMemo(() => ({
        disabled,
        required,
        variant,
        error,
        touched,
        filled,
        focused,
        onEmpty,
        onFilled,
        onFocus: () => {
            setFocused(true);
        },
        onBlur: () => {
            setFocused(false);
        },
    }), [
        disabled,
        required,
        variant,
        error,
        touched,
        filled,
        focused,
        onEmpty,
        onFilled,
        setFocused
    ])

    return (
        <FormControlContext.Provider value={childContext}>
            <Component
                ref={ref}
                className={cx(
                    styles.root,
                    classes.root,
                    classNameRoot,
                    fullWidth && styles['full-width'],
                    {
                        'field-error': error
                    }
                )}
                {...wrapperProps}
            >
                {children}
            </Component>
        </FormControlContext.Provider>
    )
})

FormControl.displayName = 'FormControl'

FormControl.InputArea = FormControlInputArea
FormControl.Label = FormControlLabel

FormControl.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    component: PropTypes.string,
    variant: PropTypes.string,
    onMetaChange: PropTypes.func,
}

export const FormControlContext = React.createContext()
export default FormControl
