import React                  from 'react'
import cx                     from 'utils/classnames'
import {
    useContext,
    getContextState
}                             from 'entrada-ui/utils'
import { FormControlContext } from 'entrada-ui/FormControl'
import styles                 from './styles.module.scss'



const TextFieldInput = React.memo(React.forwardRef(function TextFieldInput(props, ref) {
    const {
        classes = {},
        className,
        name,
        type,
        value,
        multiline,
        onChange,
        onKeyDown,
        onInput,
        onFocus,
        onBlur,
        // rtl = false,
        readOnly,
        placeholder = '',
        'aria-describedby': ariaDescribedby,
        'aria-labelledby': ariaLabelledby,
        'aria-label': ariaLabel,
        ...restProps
    } = props

    const Component = multiline ? 'textarea' : 'input'

    const context = useContext(FormControlContext)
    const { filled, touched, error, disabled, required } = getContextState({
        props,
        states: ['filled', 'touched', 'error', 'disabled', 'required'],
        context
    })

    const handleFocus = React.useCallback(event => {
        if(context.onFocus) {
            context.onFocus(event)
        }

        if(onFocus) {
            onFocus(event)
        }
    }, [onFocus, context])

    const handleBlur = React.useCallback(event => {
        if(onBlur) {
            onBlur(event)
        }
    }, [onBlur])

    const handleInput = React.useCallback(event => {
        if(onInput) {
            onInput(event)
        }
    }, [onInput])

    const handleChange = React.useCallback(event => {
        if(onChange) {
            onChange(event)
        }
    }, [onChange])

    const handleKeyDown = React.useCallback(event => {
        if(onKeyDown) {
            onKeyDown(event)
        }
    }, [onKeyDown])

    React.useEffect(()=> {
        if(context) {
            if(value) {
                context.onFilled()
            } else {
                context.onEmpty()
            }
        }
    }, [value, context])

    const showError = touched && (typeof error !== 'undefined')

    return (
        <Component
            ref={ref}
            // NOTE I suspect we still need to get some variables from restProps,
            // but we can't just pass all of them bc there are so many invalid
            // props for <input> and <textarea> that throws warning messages.
            // {...restProps}
            aria-required={required}
            aria-invalid={touched && error}
            aria-describedby={ariaDescribedby}
            aria-labelledby={ariaLabelledby}
            aria-label={ariaLabel}
            className={cx(
                styles.root,
                className,
                classes.root,
                {
                    [styles.error]: showError,
                },
                {
                    [classes.error]: showError,
                    [classes.filled]: filled
                }
            )}
            name={name}
            type={!multiline ? type : undefined}
            value={value}
            required={required}
            disabled={disabled}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onInput={handleInput}
            onFocus={handleFocus}
            onBlur={handleBlur}
            // rtl={rtl}
            readOnly={readOnly}
            placeholder={placeholder}
        />
    )
}))

TextFieldInput.etgName = 'Input'

TextFieldInput.defaultProps = {
    multiline: false
}

export default TextFieldInput
